import { createApp, reactive } from "vue";
import App from "@/views/App/App.vue";
import installElementPlus from "@/plugins/element";

document.oncontextmenu = () => false;

export const globalData = reactive({
  ws: undefined,
  connected: false,
  currentScene: null,
  scenes: [],
  currentDisplayerInfo: null,
  currentDisplayerStatus: {
    playing: true,
    progress: 0,
    resource: undefined,
    volume: undefined,
    mute: undefined,
  },
  resources: [],
  displayers: [],
  computers: [],
  dmxes: [],
  pjLinks: [],
  relays: [],
  sequencers: [],
  volumes: {},
});

const app = createApp(App);
installElementPlus(app);
app.mount("#app");
