import 前言 from "./前言.txt";
import 我与北大民盟 from "./我与北大民盟.txt";
import 北京大学民盟组织七十年发展概况 from "./北京大学民盟组织七十年发展概况.txt";
import 民盟北大组织诞生的历史背景 from "./民盟北大组织诞生的历史背景.txt";
import 民盟北大组织早期发展历程 from "./民盟北大组织早期发展历程.txt";
import 九十年代盟组织的活动情况 from "./九十年代盟组织的活动情况.txt";
import 新世纪前十年盟组织的活动情况 from "./新世纪前十年盟组织的活动情况.txt";
import 民盟北大委员会十年工作巡礼 from "./新时代、新气象 民盟北大委员会十年工作巡礼.txt";
import 忆北大民盟前辈们 from "./笃行致远，明德惟馨：忆北大民盟前辈们.txt";
import 举办论坛是集思广益与推进工作的好方式 from "./举办论坛是集思广益与推进工作的好方式.txt";
import 我与北大民盟1 from "./我与北大民盟 .txt";
import 记忆写在北大民盟70周年 from "./记忆：写在北大民盟70周年.txt";
import 我在北大民盟的两件小事 from "./我在北大民盟的两件小事.txt";
import 盟员小传 from "./盟员小传.txt";
import 从燕园走进民盟生命由此更有意义 from "./从燕园走进民盟：生命由此更有意义.txt";
import 从入盟想起 from "./从入盟想起.txt";
import 我与民盟琐记 from "./我与民盟琐记.txt";
import 我与中国民主同盟 from "./我与中国民主同盟.txt";
import 西柏坡学习印象 from "./西柏坡学习印象.txt";
import 怀念我的入盟介绍人张岱年先生和郭兰芳教授 from "./怀念我的入盟介绍人张岱年先生和郭兰芳教授.txt";
import 我与北大民盟2 from "./我与北大民盟  .txt";
import 立足岗位践初心抗击疫情担使命三支部在抗疫中践行盟员初心 from "./立足岗位践初心，抗击疫情担使命——三支部在“抗疫”中践行盟员初心.txt";
import 今年我十岁了 from "./今年，我十岁了.txt";
import 民盟与我修德奉献初心始终 from "./民盟与我：修德奉献，初心始终.txt";
import 薪尽火犹传怀念丁石孙先生 from "./薪尽火犹传——怀念丁石孙先生.txt";
import 我眼中的北大民盟温暖与榜样 from "./我眼中的北大民盟：温暖与榜样.txt";
import 从花房子到燕南园 from "./从花房子到燕南园.txt";
import 记民盟北大二支部 from "./记民盟北大二支部.txt";
import 忆陈昌笃先生二三事 from "./忆陈昌笃先生二三事.txt";
import 附录 from "./附录.txt";

export default {
  前言,
  北京大学民盟组织七十年发展概况,
  民盟北大组织诞生的历史背景,
  民盟北大组织早期发展历程,
  九十年代盟组织的活动情况,
  新世纪前十年盟组织的活动情况,
  "新时代、新气象 民盟北大委员会十年工作巡礼": 民盟北大委员会十年工作巡礼,
  盟员小传,
  我与北大民盟,
  "笃行致远，明德惟馨：忆北大民盟前辈们": 忆北大民盟前辈们,
  "我与北大民盟 ": 我与北大民盟1,
  "记忆：写在北大民盟70周年": 记忆写在北大民盟70周年,
  我在北大民盟的两件小事,
  举办论坛是集思广益与推进工作的好方式,
  "从燕园走进民盟：生命由此更有意义": 从燕园走进民盟生命由此更有意义,
  从入盟想起,
  我与民盟琐记,
  我与中国民主同盟,
  西柏坡学习印象,
  怀念我的入盟介绍人张岱年先生和郭兰芳教授,
  "我与北大民盟  ": 我与北大民盟2,
  "立足岗位践初心，抗击疫情担使命——三支部在“抗疫”中践行盟员初心":
    立足岗位践初心抗击疫情担使命三支部在抗疫中践行盟员初心,
  "今年，我十岁了": 今年我十岁了,
  "民盟与我：修德奉献，初心始终": 民盟与我修德奉献初心始终,
  "薪尽火犹传——怀念丁石孙先生": 薪尽火犹传怀念丁石孙先生,
  "我眼中的北大民盟：温暖与榜样": 我眼中的北大民盟温暖与榜样,
  从花房子到燕南园,
  记民盟北大二支部,
  忆陈昌笃先生二三事,
  附录,
};
