import texts from "@/assets/texts";
import crypto from "crypto";
import { getKey } from "@/api/getKey";
import lottie from "lottie-web";
import points from "@/assets/texts/points";

export default {
  name: "App",
  data() {
    return {
      logoLoaded: false,
      imgSrc: (index) => require(`@/assets/images/${index}.png`),
      thumbnailSrc: (index) =>
        require(`@/assets/images/thumbnails/${index}.png`),
      landscape: false,
      popUps: false,
      texts,
      loading: true,
      welcome: true,
      loaded: 0,
      counts: 9,
      loadedStatus: [
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
      ],
      title: undefined,
      text: undefined,
      playing: false,
      url: "https://www.ipttx.com/",
      mouse: {
        isDown: false,
        isMoving: false,
        mouseStart: 0,
        pageStart: 0,
      },
      points: points,
      showChapters: false,
      chapters: [0, 0.04, 0.08, 0.11, 0.22, 0.65, 0.96],
    };
  },
  mounted() {
    this.setLandscape();
    this.setLottie();
    this.setWeChat();
  },
  methods: {
    setLandscape() {
      window.onresize = () => {
        this.landscape = window?.innerWidth > window?.innerHeight;
      };
      this.landscape = window?.innerWidth > window?.innerHeight;
    },
    setLottie() {
      lottie.loadAnimation({
        container: this.$refs.loading,
        path: "lottie/data.json",
        renderer: "svg",
        loop: true,
        autoplay: true,
        name: "loading",
      });
    },
    setWeChat() {
      getKey().then((data) => {
        const appid = "wxcef020195d2121a5";
        const ticket = data.data.data;
        const noncestr = (Math.random() * 1000000000000000000).toString(16);
        const timestamp = Math.round(new Date().getTime() / 1000);
        const signature = this.getSignature(
          ticket,
          noncestr,
          timestamp,
          location.href.split("#")[0]
        );
        this.configWeChat(appid, timestamp, noncestr, signature);
      });
    },
    open(title) {
      if (!this.mouse.isMoving) {
        this.title = title;
        this.text = this.texts[title];
        this.popUps = true;
        if (this.$refs.pop) this.$refs.pop.scrollTop = 0;
      }
    },
    logoLoad() {
      this.logoLoaded = true;
    },
    load() {
      if (++this.loaded >= this.counts) {
        this.loading = false;
      }
      if (!this.loading) lottie.searchAnimations();
    },
    loadOriginal(index) {
      this.loadedStatus[index] = true;
    },
    start() {
      this.welcome = false;
      this.$refs.audio.play();
    },
    back() {
      this.$refs.screen.scrollTo({
        left: 0,
        behavior: "smooth",
      });
    },
    onPlay() {
      this.playing = true;
    },
    onPause() {
      this.playing = false;
    },
    togglePlaying() {
      this.$refs.audio.paused
        ? this.$refs.audio.play()
        : this.$refs.audio.pause();
    },
    down(e) {
      this.mouse.isDown = true;
      this.mouse.isMoving = false;
      this.mouse.mouseStart =
        window.innerHeight > window.innerWidth ? e.pageY : e.pageX;
      this.mouse.pageStart = this.$refs.screen.scrollLeft;
    },
    move(e) {
      if (this.mouse.isDown) {
        const dis =
          this.mouse.mouseStart -
          (window.innerHeight > window.innerWidth ? e.pageY : e.pageX);
        this.$refs.screen.scrollLeft = this.mouse.pageStart + dis;
        if (0 !== dis) this.mouse.isMoving = true;
      }
    },
    up() {
      this.mouse.isDown = false;
    },
    getSignature(ticket, noncestr, timestamp, url) {
      const map = new Map();
      map.set("jsapi_ticket", ticket);
      map.set("noncestr", noncestr);
      map.set("timestamp", timestamp);
      map.set("url", url);
      const plaintext = decodeURIComponent(new URLSearchParams(map).toString());
      return crypto.createHash("sha1").update(plaintext).digest("hex");
    },
    configWeChat(appId, timestamp, nonceStr, signature) {
      const wx = window.wx;
      wx.config({
        debug: false,
        appId,
        timestamp,
        nonceStr,
        signature,
        jsApiList: ["updateTimelineShareData", "updateAppMessageShareData"],
      });
      wx.ready(() => {
        wx.updateTimelineShareData({
          title: "北大民盟纪念馆",
          link: this.url,
          imgUrl: `${this.url}favicon.png`,
        });
        wx.updateAppMessageShareData({
          title: "北大民盟 70 周年纪念馆",
          desc: "一路携手 砥砺前行",
          link: this.url,
          imgUrl: `${this.url}favicon.png`,
        });
      });
      wx.error((res) => console.error(res));
    },
    go2(chapter) {
      this.$refs.screen.scrollTo({
        left: chapter * this.$refs.screen.scrollWidth,
        behavior: "smooth",
      });
      this.showChapters = false;
    },
  },
};
