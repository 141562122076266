export default [
  {
    top: 85,
    left: 125,
    width: 10,
    height: 10,
    title: "前言",
  },
  {
    top: 80,
    left: 767,
    width: 10,
    height: 10,
    title: "北京大学民盟组织七十年发展概况",
  },
  {
    top: 72,
    left: 789,
    width: 10,
    height: 10,
    title: "民盟北大组织诞生的历史背景",
  },
  {
    top: 60,
    left: 800,
    width: 10,
    height: 10,
    title: "民盟北大组织早期发展历程",
  },
  {
    top: 67,
    left: 865,
    width: 10,
    height: 10,
    title: "九十年代盟组织的活动情况",
  },
  {
    top: 54,
    left: 873,
    width: 10,
    height: 10,
    title: "新世纪前十年盟组织的活动情况",
  },
  {
    top: 39,
    left: 885,
    width: 10,
    height: 10,
    title: "新时代、新气象 民盟北大委员会十年工作巡礼",
  },
  {
    top: 23,
    left: 980,
    width: 10,
    height: 10,
    title: "盟员小传",
  },
  {
    top: 65,
    left: 1188,
    width: 10,
    height: 10,
    title: "我与北大民盟",
  },
  {
    top: 62,
    left: 1207,
    width: 10,
    height: 10,
    title: "笃行致远，明德惟馨：忆北大民盟前辈们",
  },
  {
    top: 62,
    left: 1238,
    width: 10,
    height: 10,
    title: "举办论坛是集思广益与推进工作的好方式",
  },
  {
    top: 62,
    left: 1258,
    width: 10,
    height: 10,
    title: "我与北大民盟 ",
  },
  {
    top: 62,
    left: 1271,
    width: 10,
    height: 10,
    title: "记忆：写在北大民盟70周年",
  },
  {
    top: 62,
    left: 1285,
    width: 10,
    height: 10,
    title: "我在北大民盟的两件小事",
  },
  {
    top: 45,
    left: 1341,
    width: 10,
    height: 10,
    title: "从燕园走进民盟：生命由此更有意义",
  },
  { top: 45, left: 1359, width: 10, height: 10, title: "从入盟想起" },
  {
    top: 51,
    left: 1398,
    width: 10,
    height: 10,
    title: "我与民盟琐记",
  },
  { top: 64, left: 1398, width: 10, height: 10, title: "我与中国民主同盟" },
  {
    top: 66,
    left: 1463,
    width: 10,
    height: 10,
    title: "西柏坡学习印象",
  },
  {
    top: 54,
    left: 1509,
    width: 10,
    height: 10,
    title: "怀念我的入盟介绍人张岱年先生和郭兰芳教授",
  },
  { top: 30, left: 1519, width: 10, height: 10, title: "我与北大民盟  " },
  {
    top: 32,
    left: 1528,
    width: 10,
    height: 10,
    title: "立足岗位践初心，抗击疫情担使命——三支部在“抗疫”中践行盟员初心",
  },
  { top: 62, left: 1524, width: 10, height: 10, title: "今年，我十岁了" },
  {
    top: 59,
    left: 1559,
    width: 10,
    height: 10,
    title: "民盟与我：修德奉献，初心始终",
  },
  {
    top: 56,
    left: 1638,
    width: 10,
    height: 10,
    title: "薪尽火犹传——怀念丁石孙先生",
  },
  {
    top: 56,
    left: 1652,
    width: 10,
    height: 10,
    title: "我眼中的北大民盟：温暖与榜样",
  },
  {
    top: 26,
    left: 1665,
    width: 10,
    height: 10,
    title: "从花房子到燕南园",
  },
  {
    top: 26,
    left: 1679,
    width: 10,
    height: 10,
    title: "记民盟北大二支部",
  },
  {
    top: 56,
    left: 1671,
    width: 10,
    height: 10,
    title: "忆陈昌笃先生二三事",
  },
  { top: 63, left: 4976, width: 10, height: 10, title: "附录" },
];
