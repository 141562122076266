import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import "@/style/element-variables.scss";
import cn from "element-plus/lib/locale/lang/zh-cn";
import en from "element-plus/lib/locale/lang/en";

export default function (app) {
  const locale = new RegExp("en*").test(navigator.language) ? en : cn;
  app.use(ElementPlus, { locale });
}
