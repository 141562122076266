<template>
  <div class="app">
    <div
      class="screen"
      ref="screen"
      :style="`${landscape ? undefined : 'transform: rotate(90deg);'}`"
    >
      <div
        v-if="logoLoaded"
        class="content"
        @mousedown="down"
        @mousemove="move"
        @mouseup="up"
        @mouseleave="up"
      >
        <template v-for="index in counts" :key="index">
          <img
            @load="loadOriginal(index)"
            v-if="!loading"
            v-show="this.loadedStatus[index]"
            class="image"
            :src="imgSrc(index)"
            :alt="index"
          />
          <img
            @load="load"
            v-if="!this.loadedStatus[index]"
            class="image thumbnail"
            :src="thumbnailSrc(index)"
            :alt="index"
          />
        </template>
        <div
          v-for="point in points"
          :key="point.title"
          :ref="'point'"
          class="point lottie"
          data-animation-path="lottie/point.json"
          :style="`
            top: ${point.top}vmin;
            left: ${point.left}vmin;
            width: ${point.width}vmin;
            height: ${point.height}vmin;
          `"
          @click="open(point.title)"
        />
        <div class="back" @click="back" />
      </div>
    </div>
    <div class="menu" :class="{ 'menu-90': !landscape }">
      <div class="menu-button" @click="togglePlaying">
        <div class="menu-button-icon music" :class="{ playing: playing }" />
      </div>
      <div class="menu-button" @click="showChapters = true">
        <div class="menu-button-icon go2" />
      </div>
    </div>
    <div v-if="popUps" class="pop-ups">
      <div
        :style="`${
          landscape
            ? undefined
            : 'transform: rotate(90deg) translate(-50%, -50%);'
        }`"
        class="pop-ups-content"
      >
        <div class="out-main">
          <div class="main" ref="pop">
            <div class="main-content">
              <div class="main-content-title">{{ title }}</div>
              <pre class="main-content-text">{{ text }}</pre>
            </div>
          </div>
        </div>
        <div class="close" @click="popUps = false">关闭</div>
      </div>
    </div>
    <div class="cover" v-if="showChapters">
      <div
        class="chapter"
        :style="`${landscape ? undefined : 'transform: rotate(90deg)'}`"
      >
        <div
          v-for="(chapter, index) in chapters"
          :key="chapter"
          :class="`chapter-button chapter-button${index}`"
          @click="go2(chapter)"
        />
      </div>
    </div>
    <div class="cover" v-if="welcome" @click="start">
      <div
        class="tip"
        :style="`${landscape ? undefined : 'transform: rotate(90deg)'}`"
      >
        <div class="tip-hand" />
        <div class="tip-text">左滑开始游览</div>
      </div>
    </div>
    <div v-if="loading" class="loading">
      <div ref="loading" class="loading-lottie" />
      <img
        alt="logo"
        class="loading-logo"
        src="~@/assets/icons/logo.svg"
        @load="logoLoad"
      />
      <div class="loading-text" style="top: calc(50% + 17vmin)">
        北大民盟70周年纪念馆
      </div>
      <div
        class="loading-text"
        style="top: calc(50% + 25vmin); font-size: 4.5vmin"
      >
        加载中……
      </div>
    </div>
    <audio @play="onPlay" @pause="onPause" ref="audio" hidden loop>
      <source src="~@/assets/audios/bgm.mp3" type="audio/mpeg" />
    </audio>
  </div>
</template>
<script src="./App.js" />
<style lang="scss" src="./App.scss" />
